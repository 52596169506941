import React, { useEffect, useState } from 'react'
import {
    Button,
    MenuItem,
    Typography,
    Box, TextField, FormControl, InputLabel, Select, Divider, Chip,
} from '@mui/material';
import NumberToWord from 'convert-rupees-into-words';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { titleCase } from '../../utils/index';
import firestoreService from '../../firebase/firestoreService';
import ModalReusable from '.';


const EditPropertyModal = ({ selectedEnquiry, setSelectedEnquiry, enquiryModalFlag, setEnquiryModalFlag, isOpenForEnquiryShow, setIsOpenForEnquiryShow, ENQUIRYLIST, setENQUIRYLIST, AREA_NAME }) => {
    const theme = useTheme();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [segmentOptions, setSegmentOption] = useState({});
    const [subSegmentOptions, setSubSegmentOption] = useState({});


    const validationSchema = Yup.object().shape({
        ownerName: Yup.string().required('Required'),
        ownerNumber: Yup.string().required('Required'),
        schemeName: Yup.string().required('Required'),
        landmark: Yup.string().required('Required'),
        enquiryFor: Yup.string().required('Required'),
        condition: Yup.string().required('Required'),
        propertyType: Yup.string().required('Required'),
        segment: Yup.string().required('Required'),
        measureMent: Yup.string().required('Required'),
        unit: Yup.string().required('Required'),
        bhkOffice: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
        budget: Yup.string().required('Required'),
        area: Yup.array().required('Required'),
        remark: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            ownerName: selectedEnquiry?.ownerName || '',
            ownerNumber: selectedEnquiry?.ownerNumber || '',
            schemeName: selectedEnquiry?.schemeName || '',
            landmark: selectedEnquiry?.landmark || '',
            enquiryFor: selectedEnquiry?.enquiryFor || '',
            condition: selectedEnquiry?.condition || '',
            propertyType: selectedEnquiry?.propertyType || '',
            segment: selectedEnquiry?.segment || '',
            measureMent: selectedEnquiry?.measureMent || '',
            unit: selectedEnquiry?.unit || '',
            bhkOffice: selectedEnquiry?.bhkOffice || '',
            status: selectedEnquiry?.status || '',
            budget: selectedEnquiry?.budget || '',
            area: selectedEnquiry?.area || [],
            remark: selectedEnquiry?.remark || '',
            createdAt: selectedEnquiry?.createdAt || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (selectedEnquiry) {
                handleUpadateEnquiry(values);
            } else {
                handleCreateProperty(values);
            }
        },
    });

    useEffect(() => {
        fetchAllSegmentMenu();

        return () => {
            setSegmentOption({});
            setSubSegmentOption({});
        }
    }, [])

    const fetchAllSegmentMenu = async () => {
        try {
            const options = await firestoreService.getDocument('default-values', 'segment');
            const { segmentOptions, subSegmentOptions } = options;
            if (subSegmentOptions) {
                setSegmentOption(segmentOptions)
                setSubSegmentOption(subSegmentOptions);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleCreateProperty = async (values) => {
        try {
                const remarkHistory = [{
                    userId: currentUser.id,
                    date: moment(new Date()).format(),
                    remark: `Property created`
                }];
                const valueWithHistory = { ...values, remarkHistory };
                const enquiryData = await firestoreService.addDocument('properties', valueWithHistory);
                if (enquiryData) {
                    setENQUIRYLIST(prev => [enquiryData, ...prev])
                    formik.resetForm();
                    handleCloseModal();
                    toast.success('Property created successfully');
                } else {
                    toast.error('something went wrong')
                }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleUpadateEnquiry = async (values) => {
        try {
            const userData = await firestoreService.updateDocument('properties', selectedEnquiry.id, values);
            if (userData) {
                setENQUIRYLIST(ENQUIRYLIST.map(user => user?.id === selectedEnquiry?.id ? { id: selectedEnquiry.id, ...values } : user));
                formik.resetForm();
                handleCloseModal();
                setSelectedEnquiry(null);
                toast.success('Property updated successfully');
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleCloseModal = () => {
        setIsOpenForEnquiryShow(false);
        setEnquiryModalFlag(false);
        setSelectedEnquiry(null);
        formik.resetForm();
    };

    return (
        <ModalReusable
            open={enquiryModalFlag}
            onClose={handleCloseModal}
            header={isOpenForEnquiryShow ? "Property Details " : (selectedEnquiry?.id ? 'Update Property' : 'Add Property')}
        >
                <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
                    <form onSubmit={formik.handleSubmit}>
                    <TextField
                            fullWidth
                            margin="normal"
                            label="Owner Name"
                            variant="outlined"
                            name="ownerName"
                            value={formik.values.ownerName}
                            onChange={formik.handleChange}
                            error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
                            helperText={formik.touched.ownerName && formik.errors.ownerName}
                        />
                         <TextField
                            fullWidth
                            margin="normal"
                            label="Owner Contact No."
                            variant="outlined"
                            name="ownerNumber"
                            type='tel'
                            value={formik.values.ownerNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.ownerNumber && Boolean(formik.errors.ownerNumber)}
                            helperText={formik.touched.ownerNumber && formik.errors.ownerNumber}
                        />
                    <TextField
                            fullWidth
                            margin="normal"
                            label="Scheme Name"
                            variant="outlined"
                            name="schemeName"
                            value={formik.values.schemeName}
                            onChange={formik.handleChange}
                            error={formik.touched.schemeName && Boolean(formik.errors.schemeName)}
                            helperText={formik.touched.schemeName && formik.errors.schemeName}
                        />
                         <TextField
                            fullWidth
                            margin="normal"
                            label="Landmark"
                            variant="outlined"
                            name="landmark"
                            value={formik.values.landmark}
                            onChange={formik.handleChange}
                            error={formik.touched.landmark && Boolean(formik.errors.landmark)}
                            helperText={formik.touched.landmark && formik.errors.landmark}
                        />
                         <FormControl fullWidth margin="normal">
                            <InputLabel>Location:</InputLabel>
                            <Select
                                labelId="area-label"
                                id="area"
                                label="Location:"
                                name="area"
                                value={formik.values.area}
                                onChange={formik.handleChange}
                                error={formik.touched.area && Boolean(formik.errors.area)}
                                onBlur={formik.handleBlur}
                                multiple  // Add the 'multiple' attribute to enable multiple selection
                                renderValue={(selected) => (
                                    <div>
                                        {selected?.map((value) => (
                                            <Chip key={value} label={AREA_NAME?.find((area) => area?.id === value).name} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {AREA_NAME?.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }, index) => (
                                    <MenuItem value={id} key={index}>{name}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.area && formik.errors.area && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.area}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>For</InputLabel>
                            <Select
                                labelId="enquiryFor-label"
                                id="enquiryFor"
                                label="For"
                                name="enquiryFor"
                                value={formik.values.enquiryFor}
                                onChange={formik.handleChange}
                                error={formik.touched.enquiryFor && Boolean(formik.errors.enquiryFor)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="rent">Rent</MenuItem>
                                <MenuItem value="buy">Buy</MenuItem>
                            </Select>
                            {formik.touched.enquiryFor && formik.errors.enquiryFor && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.enquiryFor}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                type="number"
                                name="budget"
                                value={formik.values.budget}
                                onChange={formik.handleChange}
                                error={formik.touched.budget && Boolean(formik.errors.budget)}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                label="Rent/Sell Price:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {formik.touched.budget && formik.errors.budget && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.budget}</Box>
                            )}
                            <Typography variant='h6' fontSize={10}>{formik?.values?.budget && NumberToWord(formik?.values?.budget)}</Typography>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Property Type</InputLabel>
                            <Select
                                labelId="property-type-label"
                                id="propertyType"
                                label="Select Property Type"
                                name="propertyType"
                                value={formik.values.propertyType}
                                onChange={
                                    formik.handleChange}
                                error={formik.touched.propertyType && Boolean(formik.errors.propertyType)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="residential">Residential</MenuItem>
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="plot">Plot</MenuItem>
                            </Select>
                            {formik.touched.propertyType && formik.errors.propertyType && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.propertyType}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Segment:</InputLabel>
                            <Select
                                labelId="segment-label"
                                id="segment"
                                label="Segment:"
                                name="segment"
                                value={formik.values.segment}
                                onChange={formik.handleChange}
                                error={formik.touched.segment && Boolean(formik.errors.segment)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {segmentOptions[formik.values.propertyType]?.map((sagment, index) => (
                                    <MenuItem value={sagment} key={index}>{sagment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.segment && formik.errors.segment && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.segment}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Bhk/Office:</InputLabel>
                            <Select
                                labelId="bhk-label"
                                id="bhkOffice"
                                label="Bhk/Office:"
                                name="bhkOffice"
                                value={formik.values.bhkOffice}
                                onChange={formik.handleChange}
                                error={formik.touched.bhkOffice && Boolean(formik.errors.bhkOffice)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {subSegmentOptions[formik.values.segment]?.map((subSegment, index) => (
                                    <MenuItem value={subSegment} key={index}>{subSegment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.bhkOffice && formik.errors.bhkOffice && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.bhkOffice}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Condition</InputLabel>
                            <Select
                                labelId="condition-label"
                                id="condition"
                                label="Condition"
                                name="condition"
                                value={formik.values.condition}
                                onChange={formik.handleChange}
                                error={formik.touched.condition && Boolean(formik.errors.condition)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="fullyFurnished">Fully Furnished</MenuItem>
                                <MenuItem value="furnished">Furnished</MenuItem>
                                <MenuItem value="kitchenFix">Kitchen-fix</MenuItem>
                                <MenuItem value="semiFurnished">Semi Furnished</MenuItem>
                                <MenuItem value="unfurnished">Unfurnished</MenuItem>
                            </Select>
                            {formik.touched.source && formik.errors.source && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.source}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    value={formik.values.unit}
                                    onChange={formik.handleChange}
                                    name="unit"
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Unit' }}
                                    style={{ marginRight: '8px', width: '80px' }}
                                >
                                    <MenuItem value="sqft">sqft</MenuItem>
                                    <MenuItem value="sqyd">sqyd</MenuItem>
                                </Select>
                                <TextField
                                    type="number"
                                    name="measureMent"
                                    value={formik.values.measureMent}
                                    onChange={formik.handleChange}
                                    error={formik.touched.measureMent && Boolean(formik.errors.measureMent)}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    label="Sq feet / Sq Yard ?:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0, // Set the minimum allowed value
                                        },
                                    }}
                                />
                            </div>
                            {formik.touched.measureMent && formik.errors.measureMent && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.measureMent}</Box>
                            )}
                            {formik.touched.unit && formik.errors.unit && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.unit}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Status:</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                label="Status:"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="open">Open</MenuItem>
                                <MenuItem value="close">Close</MenuItem>
                            </Select>
                            {formik.touched.status && formik.errors.status && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.status}</Box>
                            )}
                        </FormControl>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Detail of Property"
                            variant="outlined"
                            name="remark"
                            value={formik.values.remark}
                            onChange={formik.handleChange}
                            error={formik.touched.remark && Boolean(formik.errors.remark)}
                            helperText={formik.touched.remark && formik.errors.remark}
                            multiline
                            rows={4}
                        />
                        <Button variant="contained"
                            type='submit'
                            size='medium'
                            sx={{ marginTop: '10px' }}
                        >
                            {selectedEnquiry?.id ? 'Update' : 'Add'} Property
                        </Button>
                    </form>
                </Box>
        </ModalReusable>
    )
}

export default EditPropertyModal