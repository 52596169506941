import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    CircularProgress,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    Slider
} from '@mui/material';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import EditPropertyModal from '../components/modal/EditPropertyModal';
import { formatCurrency, handleCheckPermissionByRole, titleCase } from '../utils/index';
import firestoreService from '../firebase/firestoreService';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { ACTION_TYPE, ENTITIES_TYPE } from '../constant/index';
import Label from '../components/label';
import ModalReusable from '../components/modal';

const TABLE_HEAD = [
    { id: 'sr.no', label: 'Sr.No.', alignRight: false },
    { id: 'addedAt', label: 'Added At', alignRight: false },
    { id: 'ownerName', label: 'Owner Name', alignRight: false },
    { id: 'ownerNumber', label: 'Owner Contact', alignRight: false },
    { id: 'schemeName', label: 'Scheme Name', alignRight: false },
    { id: 'landmark', label: 'Landmark', alignRight: false },
    { id: 'area', label: 'Area', alignRight: false },
    { id: 'budget', label: 'Budget', alignRight: false },
    { id: 'enquiryFor', label: 'For', alignRight: false },
    { id: 'propertyType', label: 'Property Type', alignRight: false },
    { id: 'segment', label: 'Segment', alignRight: false },
    { id: 'bhkOffice', label: 'BHK/Office', alignRight: false },
    { id: 'condition', label: 'Condition', alignRight: false },
    { id: 'measureMent', label: 'Sq feet/Sq Yard', alignRight: false },
    { id: 'remark', label: 'Remark', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '', label: 'Action' },
];

const validationSchema = Yup.object().shape({
    enquiryFor: Yup.string().required('For is required'),
    propertyType: Yup.string().required('Property Type is required'),
    segment: Yup.string().required('Segment is required'),
    bhkOffice: Yup.string().required('BHK/Office is required'),
});

export default function ProperyPage() {
    const theme = useTheme();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [open, setOpen] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [filterByUser, setFilterByUser] = useState('all');
    const [ENQUIRYLIST, setENQUIRYLIST] = useState([])
    const [optionalENQUIRYLIST, setOptionalENQUIRYLIST] = useState([])
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [segmentOptions, setSegmentOption] = useState({});
    const [subSegmentOptions, setSubSegmentOption] = useState({});
    const [enquiryModalFlag, setEnquiryModalFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenForEnquiryShow, setIsOpenForEnquiryShow] = useState(false);
    const [showCloseEnquiryFlag, setShowCloseEnquiryFlag] = useState(false);
    const [isOpenAdvanceSearch, setIsOpenAdvanceSearch] = useState(false);
    const [AREA_NAME, setAreaName] = useState([])

    const formik = useFormik({
        initialValues: {
            enquiryFor: '',
            propertyType: '',
            segment: '',
            bhkOffice: '',
            minBudget: null,
            maxBudget: null,
            area: [],
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsOpenAdvanceSearch(false);
            await handleAdvancedSearchEnquiry(values);
        },
    });

    useEffect(() => {
        fetchAllEnquiry();
        fetchAllArea();

        return () => {
            setENQUIRYLIST([]);
        }
    }, []);

    useEffect(() => {
        fetchAllSegmentMenu();

        return () => {
            setSegmentOption({});
            setSubSegmentOption({});
        }
    }, []);

    useEffect(() => {
        if (showCloseEnquiryFlag) {
            const closeEnquiry = optionalENQUIRYLIST.filter(enquiry => {
                return enquiry.status === 'close'
            });
            setENQUIRYLIST(closeEnquiry);
        } else {
            const openEnquiry = optionalENQUIRYLIST.filter(enquiry => {
                return enquiry.status !== 'close'
            });
            setENQUIRYLIST(openEnquiry);
        }
        return () => {
            setENQUIRYLIST([]);
        }
    }, [showCloseEnquiryFlag])

    const fetchAllSegmentMenu = async () => {
        try {
            const options = await firestoreService.getDocument('default-values', 'segment');
            const { segmentOptions, subSegmentOptions } = options;
            if (subSegmentOptions) {
                setSegmentOption(segmentOptions)
                setSubSegmentOption(subSegmentOptions);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const fetchAllArea = async () => {
        try {
            const areaData = await firestoreService.getDocument('default-values', 'area');
            const areaArray = areaData?.area;
            if (areaArray?.length) {
                setAreaName(areaArray);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const fetchAllEnquiry = async () => {
        try {
            setIsLoading(true);
            let enquiryList;
            if (['admin', 'back_office'].includes(currentUser?.role)) {
                enquiryList = await firestoreService.getAllDocuments('properties');
            } else {
                const whereClause = { field: 'assignTo', operator: '==', value: currentUser?.id };
                const whereClause2 = { field: 'status', operator: '!=', value: 'close' };
                enquiryList = await firestoreService.findDocuments('properties', whereClause, whereClause2);
            }
            if (enquiryList.length) {
                const openEnquiry = enquiryList.filter(enquiry => {
                    return enquiry.status !== 'close'
                });
                setENQUIRYLIST(openEnquiry);
                setOptionalENQUIRYLIST(enquiryList);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error', error)
            setIsLoading(false);
        }
    }

    const handleAdvancedSearchEnquiry = async (values) => {
        try {
            setIsLoading(true);
            const { enquiryFor, propertyType, segment, bhkOffice, minBudget, maxBudget, area } = values;
            const conditions = [];

            if (enquiryFor) conditions.push({ field: "enquiryFor", operator: "==", value: enquiryFor });
            if (propertyType) conditions.push({ field: "propertyType", operator: "==", value: propertyType });
            if (segment) conditions.push({ field: "segment", operator: "==", value: segment });
            if (bhkOffice) conditions.push({ field: "bhkOffice", operator: "==", value: bhkOffice });
            if (minBudget && maxBudget) {
                conditions.push({ field: "budget", operator: ">=", value: minBudget });
                conditions.push({ field: "budget", operator: "<=", value: maxBudget });
            }
            if (area.length) {
                conditions.push({ field: "area", operator: "array-contains-any", value: area });
            }
            const enquiryList = await firestoreService.handleAdvancedSearch("properties", conditions);
            if (enquiryList.length) {
                const openEnquiry = enquiryList.filter(enquiry => {
                    return enquiry.status !== 'close'
                });
                setENQUIRYLIST(openEnquiry);
                setOptionalENQUIRYLIST(enquiryList);
            } else {
                setENQUIRYLIST([]);
                setOptionalENQUIRYLIST([]);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    };

    const handleDeleteEnquiry = async (id) => {
        try {
            setOpen(false);
            const response = await firestoreService.deleteDocument('properties', id);
            if (response) {
                setENQUIRYLIST(ENQUIRYLIST.filter(enquiry => enquiry.id !== selectedEnquiry?.id));
                setSelectedEnquiry(null);
                handleCloseMenu();
                toast.success('Enquiry deleted successfully');
            } else {
                toast.error('something went wrong')
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleOpenModal = () => {
        setEnquiryModalFlag(true);
        setOpen(false);
    };

    const handleOpenMenu = (event, data) => {
        setOpen(event.currentTarget);
        setSelectedEnquiry(data);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setSelectedEnquiry(null);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    const handleFilterByuser = (event) => {
        setFilterByUser(event.target.value);
    };

    const handleShowClosedEnquiry = () => {
        setShowCloseEnquiryFlag(!showCloseEnquiryFlag);
    }

    function applySearchFilter(array, query, userQuiery) {
        if (query || userQuiery !== 'all') {
            return array.filter((_enquiry) => {
                const nameMatches = _enquiry.schemeName &&
                    _enquiry.schemeName.toString().trim().toLowerCase().includes(query?.toString()?.trim()?.toLowerCase());

                return nameMatches;
            });
        }
        return array;
    }

    const handleCloseModal = () => {
        setIsOpenAdvanceSearch(false)
    };

    const handleClearAllFilter = () => {
        setIsOpenAdvanceSearch(false)
        formik.resetForm();
        fetchAllEnquiry();
    }

    const filteredEnquiry = applySearchFilter(ENQUIRYLIST, filterName, filterByUser);

    const isNotFound = !filteredEnquiry.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Properties | WhiteStone </title>
            </Helmet>

            <Container maxWidth={'xl'} sx={{ marginBottom: '-100px' }}>
                <Stack direction="row" mb={3}>
                    {handleCheckPermissionByRole(ENTITIES_TYPE.Property, ACTION_TYPE.Add) ?
                        <>
                            <Button variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleOpenModal}
                                sx={{ marginRight: '2px', fontSize: '12px' }}
                            >
                                New Property
                            </Button>
                            <Button variant="contained"
                                startIcon={<Iconify icon="mdi:eye" />}
                                onClick={handleShowClosedEnquiry}
                                sx={{ marginRight: '2px', fontSize: '12px' }}
                            >
                                {!showCloseEnquiryFlag ? 'Closed Property' : 'Open Property'}
                            </Button>
                            <Button variant="contained"
                                startIcon={<Iconify icon="mdi:database-search-outline" />}
                                onClick={() => setIsOpenAdvanceSearch(true)}
                                sx={{ fontSize: '12px' }}
                            >
                                Advance Search
                            </Button>
                        </> : null}
                </Stack>

                <Card>
                    <UserListToolbar
                        type="Property"
                        userCount={filteredEnquiry?.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        userList={[]}
                        filterByUser={filterByUser}
                        onFilterByUser={handleFilterByuser}
                        isShowUserFilter={false}
                    />
                    <Scrollbar>
                        <TableContainer
                            sx={{
                                maxHeight: '78vh', // Set the desired fixed height
                                overflowY: 'auto', // Add vertical scroll
                            }}>
                            <Table
                                size='small'
                                padding='normal'
                                stickyHeader>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                />
                                {isLoading ?
                                    <TableRow>
                                        <TableCell align="center" colSpan={23} sx={{ py: 10 }}>
                                            <Paper>
                                                <CircularProgress color="inherit" />
                                            </Paper>
                                        </TableCell>
                                    </TableRow> : <TableBody>
                                        {!filterName.length && !filteredEnquiry.length ?
                                            (<TableRow>
                                                <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            No Data found
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>) : filteredEnquiry?.sort((a, b) => showCloseEnquiryFlag ? moment(b.nfd) - moment(a.nfd) : moment(b.createdAt) - moment(a.createdAt)).map((row, index) => {
                                                const { ownerName, ownerNumber, createdAt, enquiryFor, schemeName,landmark,condition, propertyType, segment, measureMent, unit, bhkOffice, status, budget, area, remark } = row;
                                                return (
                                                    <TableRow hover key={index} tabIndex={-1}>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(new Date(createdAt)).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell align="center">{ownerName}</TableCell>
                                                        {handleCheckPermissionByRole(ENTITIES_TYPE.Property, ACTION_TYPE.Read) ? <TableCell align="center">{ownerNumber}</TableCell> : "-"}
                                                        <TableCell align="center">{schemeName}</TableCell>
                                                        <TableCell align="center">{landmark}</TableCell>
                                                        <TableCell align="center">
                                                            <ul>
                                                                {area && area.map((areaId, index) => (
                                                                    <li key={index}>{AREA_NAME.find((area) => area.id === areaId)?.name}</li>
                                                                ))}
                                                            </ul>
                                                        </TableCell>
                                                        <TableCell align="center">{formatCurrency(budget)}</TableCell>
                                                        <TableCell align="center">{enquiryFor}</TableCell>
                                                        <TableCell align="center">{propertyType}</TableCell>
                                                        <TableCell align="center">{segment}</TableCell>
                                                        <TableCell align="center">{bhkOffice}</TableCell>
                                                        <TableCell align="center">{condition}</TableCell>
                                                        <TableCell align="center">{`${measureMent} ${unit}`}</TableCell>
                                                        <TableCell align="center">{remark}</TableCell>
                                                        <TableCell align="center">
                                                            <Label color={status === 'close' ? 'error' : status === 'hold' ? 'warning' : 'success'}>{status}</Label>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton size="large" color="inherit" onClick={(e) => handleCheckPermissionByRole(ENTITIES_TYPE.Property, ACTION_TYPE.Update) ? handleOpenMenu(e, row) : null}>
                                                                <Iconify icon={'eva:more-vertical-fill'} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>}
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                 {handleCheckPermissionByRole(ENTITIES_TYPE.Property, ACTION_TYPE.Update) ? <MenuItem onClick={handleOpenModal}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem> : null}

                {handleCheckPermissionByRole(ENTITIES_TYPE.Property, ACTION_TYPE.Delete) ? <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteEnquiry(selectedEnquiry?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem> : null}
            </Popover>

            {/* modal EditPropertyModal */}
            <EditPropertyModal
                selectedEnquiry={selectedEnquiry}
                setSelectedEnquiry={setSelectedEnquiry}
                setENQUIRYLIST={setENQUIRYLIST}
                ENQUIRYLIST={ENQUIRYLIST}
                enquiryModalFlag={enquiryModalFlag}
                setEnquiryModalFlag={setEnquiryModalFlag}
                isOpenForEnquiryShow={isOpenForEnquiryShow}
                setIsOpenForEnquiryShow={setIsOpenForEnquiryShow}
                AREA_NAME={AREA_NAME}
            />

            <ModalReusable
                open={isOpenAdvanceSearch}
                onClose={handleCloseModal}
                header={`Property - Advance search`}
            >
                <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>For</InputLabel>
                            <Select
                                labelId="enquiryFor-label"
                                id="enquiryFor"
                                label="For"
                                name="enquiryFor"
                                value={formik.values.enquiryFor}
                                onChange={formik.handleChange}
                                error={formik.touched.enquiryFor && Boolean(formik.errors.enquiryFor)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="rent">Rent</MenuItem>
                                <MenuItem value="buy">Buy</MenuItem>
                            </Select>
                            {formik.touched.enquiryFor && formik.errors.enquiryFor && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.enquiryFor}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Property Type</InputLabel>
                            <Select
                                labelId="property-type-label"
                                id="propertyType"
                                label="Select Property Type"
                                name="propertyType"
                                value={formik.values.propertyType}
                                onChange={
                                    formik.handleChange}
                                error={formik.touched.propertyType && Boolean(formik.errors.propertyType)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="residential">Residential</MenuItem>
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="plot">Plot</MenuItem>
                            </Select>
                            {formik.touched.propertyType && formik.errors.propertyType && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.propertyType}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Segment:</InputLabel>
                            <Select
                                labelId="segment-label"
                                id="segment"
                                label="Segment:"
                                name="segment"
                                value={formik.values.segment}
                                onChange={formik.handleChange}
                                error={formik.touched.segment && Boolean(formik.errors.segment)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {segmentOptions[formik.values.propertyType]?.map((sagment, index) => (
                                    <MenuItem value={sagment} key={index}>{sagment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.segment && formik.errors.segment && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.segment}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Bhk/Office:</InputLabel>
                            <Select
                                labelId="bhk-label"
                                id="bhkOffice"
                                label="Bhk/Office:"
                                name="bhkOffice"
                                value={formik.values.bhkOffice}
                                onChange={formik.handleChange}
                                error={formik.touched.bhkOffice && Boolean(formik.errors.bhkOffice)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {subSegmentOptions[formik.values.segment]?.map((subSegment, index) => (
                                    <MenuItem value={subSegment} key={index}>{subSegment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.bhkOffice && formik.errors.bhkOffice && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.bhkOffice}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                                <TextField
                                    type="number"
                                    name="minBudget"
                                    value={formik.values.minBudget}
                                    onChange={formik.handleChange}
                                    error={formik.touched.minBudget && Boolean(formik.errors.minBudget)}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    label="min-budget:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Typography>-</Typography>
                                <TextField
                                    type="number"
                                    name="maxBudget"
                                    value={formik.values.maxBudget}
                                    onChange={formik.handleChange}
                                    error={formik.touched.maxBudget && Boolean(formik.errors.maxBudget)}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    label="max-budget:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Stack>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Area:</InputLabel>
                            <Select
                                labelId="area-label"
                                id="area"
                                label="Area:"
                                name="area"
                                value={formik.values.area}
                                onChange={formik.handleChange}
                                error={formik.touched.area && Boolean(formik.errors.area)}
                                onBlur={formik.handleBlur}
                                multiple  // Add the 'multiple' attribute to enable multiple selection
                                renderValue={(selected) => (
                                    <div>
                                        {selected?.map((value) => (
                                            <Chip key={value} label={AREA_NAME?.find((area) => area?.id === value).name} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {AREA_NAME?.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }, index) => (
                                    <MenuItem value={id} key={index}>{name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                            <Button variant="contained"
                                type='submit'
                                size='medium'
                                sx={{ marginTop: '10px' }}
                            >
                                Search Property
                            </Button>
                            <Button variant="text"
                                type='button'
                                size='medium'
                                sx={{ marginTop: '10px' }}
                                onClick={handleClearAllFilter}
                            >
                                Clear all filter
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </ModalReusable>
        </>
    );
}