import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Select, MenuItem } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 60,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 200,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  userCount: PropTypes.number,
};

export default function UserListToolbar({ filterName, onFilterName, userCount, type, userList, filterByUser, onFilterByUser, isShowUserFilter = true }) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  return (
    <StyledRoot>
      <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder={`Search ${type}...`}
        sx={{ width: 200, height: 40 }}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      /> 
      {isShowUserFilter ? <>{['admin', 'back_office'].includes(currentUser?.role) && filterByUser ? <Select
        value={filterByUser}
        onChange={onFilterByUser}
        sx={{ width: 100, height: 40, marginLeft: '5px' }}
      >
        <MenuItem value="all">All</MenuItem>
        {userList?.map((user, index) => (
          <MenuItem value={user.id} key={index}>{user.name}</MenuItem>
        ))}
      </Select> : null} </> : null}
      <Typography fontWeight="bold">{userCount} {type}</Typography>
    </StyledRoot>
  );
}
